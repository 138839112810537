<template>
  <DefaultLayout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{
          $store.state.pageIcon
        }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="logout">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </template>
    <v-container>
      <v-card width="100%" elevation="0" class="pa-0">
        <VStudent
          v-if="$store.state.loginInfo.userType == 'student'"
        ></VStudent>
      </v-card>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import VStudent from "@/views/Account/Student.vue";

export default {
  name: "Account",
  components: { DefaultLayout, VStudent },

  data: () => ({
    account: {},
  }),

  methods: {
    getInfo() {
      try {
        this.$axios
          .get("user/info")
          .then((res) => {
            if (res.data.status == "success") {
              this.account = res.data.data.account;
            } else {
              this.account = {};
            }
          })
          .catch((error) => {
            console.log(error);
            this.showAlert("error", "Internal Server Error!");
            this.$awn.alert();
            this.$store.state.showLoadingDialog = false;
          })
          .finally(() => (this.$store.state.showLoadingDialog = false));
      } catch (error) {
        this.showAlert("error", error);
      }
    },

    logout() {
      let notifier = this.$awn;
      let onOk = () => {
        this.$store.state.apiToken = "";
        this.$store.state.loginInfo.username = "";
        this.$store.state.loginInfo.firstName = "";
        this.$store.state.loginInfo.lastName = "";
        this.$store.state.loginInfo.profileImage = "";
        this.$store.state.loginInfo.email = "";
        this.$store.state.loginInfo.userType = "";

        this.$store.state.isLoggedIn = false;
        // this.$store.state.showLoadingDialog = false;
        this.$router.push("/login");
      };
      let onCancel = () => {
        return null;
      };
      notifier.confirm("Apa Anda yakin?", onOk, onCancel, {
        labels: {
          confirm: "Keluar Aplikasi",
        },
      });
    },
  },

  mounted() {
    this.setPageTitle("Akun", "mdi-account");
  },
};
</script>